import * as React from "react";
import Navbar from "../components/layout";
import { Link, graphql, useStaticQuery } from "gatsby"

const BlogPage = () => {

    const data = useStaticQuery(graphql`
    query{
        allContentfulBlog(limit: 10) {
          nodes {
            title
            slug
            postBody {
              id
              internal {
                content
              }
            }
          }
        }
      }      
    `);
    console.log(data);

  return (
    <main className="flex">
      <div className="mx-auto">
        <Navbar pageTitle="My Blog Posts"></Navbar>
        <ul>
        {data.allContentfulBlog.nodes.map(node => {
          return (
            <li>
              <Link to={`/blog/${node.slug}/`}>
                <h2>{node.title}</h2>
                <p>{node.publishedDate}</p>
              </Link>
            </li>
          )
        })}
      </ul>
      </div>
    </main>
  );
};


export default BlogPage;
